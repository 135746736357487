import { Component } from './flags'
import { H2, H3, Text, Quote, Section, Paragraph, Article } from './components'
import { Arrow, Trame } from './components'

import marion from './_images/marion-guigue-co-schooling-academy.jpg'

export const Equipe = () => (
  <Section id="equipe">
    <Trame
      style={{
        top: '45%',
        right: -350,
        transform: 'scaleX(-1) rotate(-180deg)',
      }}
    />

    <H2 mb60__m id="concept">
      Qui sommes-nous
    </H2>

    <Article mb120 mb60__m>
      <Paragraph w60p>
        <H3>La fondatrice du projet</H3>
        <Text>
          Marion Guigue a 38 ans, est originaire de Savoie; elle a travaillé
          durant 13 ans dans l'enseignement supérieur privé, à Paris et à
          Londres, en occupant des fonctions de développement pédagogique,
          commercial et marketing. Passionnée par l'éducation et la formation,
          Marion a eu l'occasion d'accompagner et orienter de nombreux jeunes
          pour leurs études supérieures.
        </Text>
      </Paragraph>
      <Picture
        title="Marion Guigue fondatrice de Co.schooling Academy"
        style={{ background: `top / cover url(${marion})` }}
      />
    </Article>

    <Article>
      <Paragraph>
        <Quote>
          « Les jeunes arrivent trop souvent en études supérieures avec de
          fortes lacunes : ceux qui apprennent à comprendre, à se questionner, à
          être curieux, feront LA différence. »
        </Quote>
      </Paragraph>
      <Paragraph flex ai_flex_start flex_column mt60__m>
        <Text>
          C'est la raison pour laquelle elle a eu l’idée de proposer des
          compléments éducatifs aux jeunes de 7 à 11 ans, pour leur permettre de
          susciter au maximum leur curiosité, les ouvrir au monde qui les
          entoure, et ce, le plus tôt possible.
        </Text>
        <Link href="https://www.linkedin.com/in/marionguigue/" target="_blank">
          <Arrow color="#95c11f" stroke_width={10} width={28} /> Lien vers son
          LinkedIn
        </Link>
      </Paragraph>
    </Article>
  </Section>
)

const Link =
  Component.bb.bw2.b_green_csa.mt35.fs20__xxxs.green_csa.fs25.fw300.a()
const Picture = Component.w350.h350.mt60__m.div()
