import { Component } from './flags'
import { H4 } from './components'
import { Icon } from './icons'

export const KeyFeatures = ({ set_is_open }) => (
  <Wrapper id="keys">
    <Keys>
      <KeyFeature>
        <Icon icon="ateliers" />
        <H4 w85p>Des ateliers éducatifs vidéo</H4>
        <Detail>Avec un format de 15 min adapté aux enfants</Detail>
      </KeyFeature>
      <KeyFeature mt100__s>
        <Icon icon="supports" />
        <H4 w85p>Des supports pédagogiques</H4>
        <Detail>Pour compléter et poursuivre les activités hors écran</Detail>
      </KeyFeature>
      <KeyFeature mt100__s>
        <Icon icon="themes" />
        <H4 w85p>Des thèmes qui ouvrent l’esprit</H4>
        <Detail>
          Pour apprendre ce qu’ils n’apprennent pas ou peu à l’école
        </Detail>
      </KeyFeature>
    </Keys>
  </Wrapper>
)

const Wrapper =
  Component.bg_light_blue_csa.blend_multiply.ph100.ph50__m.pt100.pb110.mt50.mt0__xs.flex.flex_column.ai_center.section()
const Keys = Component.flex.flex_column__s.jc_between.div()
const KeyFeature =
  Component.flex.flex_column.ai_center.text_center.w30p.w100p__s.article()
const Detail = Component.w85p.lh35.p()
