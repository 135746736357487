import { Component } from './flags'

export const Icon = ({ width = 80, icon, ...props }) => (
  <Svg
    width={width}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    {...props}
  >
    <g fill="none" stroke="#2b475e" strokeWidth={30}>
      {icons[icon]}
    </g>
  </Svg>
)

const icons = {
  ateliers: (
    <>
      <path d="M888.55 156.69H108.39A10.31 10.31 0 0 0 98.08 167v661.06a15.24 15.24 0 0 0 15.24 15.25h774.15A15.25 15.25 0 0 0 902.72 828l-3.86-661a10.31 10.31 0 0 0-10.31-10.31Z" />
      <path d="m97.28 156.74 148.5 148.5M260.78 156.74l148.5 148.5M424.28 156.74l148.5 148.5M587.78 156.74l148.5 148.5M751.28 156.74l148.5 148.5" />
      <path d="M97.72 305.31h802" />
      <path d="m415.72 695.14 220-120.83-220-120.83v241.66z" />
    </>
  ),
  supports: (
    <>
      <path d="M670.33 829.93v74.67H179.67V245.4h74.66" />
      <path d="M745.33 754.93v74.67H254.67V170.4h74.66" />
      <path d="M820 261.07v493.86H329.33V95.73h325.34L820 261.07z" />
      <path d="M820 261.07H654.67V95.73M434.93 369.87h277.34M434.93 482.93h277.34M434.93 596h277.34" />
    </>
  ),
  themes: (
    <>
      <path d="M409.82 728.41v39.67a34.87 34.87 0 0 0 34.87 34.86H545a34.87 34.87 0 0 0 34.87-34.86v-34.73M434.82 820.86v17.9a32.43 32.43 0 0 0 32.42 32.42h55.16a32.43 32.43 0 0 0 32.42-32.42v-17.9M90.82 422.78h85.38M813.44 422.78h85.38M494.82 25.71v85.38M196.39 140.12l60.38 60.38M793.25 140.12l-60.38 60.38" />
      <path d="M497.58 193.89c125.75 1.48 227.24 103.88 227.24 230a229.5 229.5 0 0 1-81.36 175.51c-11 9.33-18.14 12.27-28.75 32.12l-.56.82c-5.82 12.63-7.84 20.93-9.2 29a323.51 323.51 0 0 1-8.74 37.52l-1.21 4.21a40.79 40.79 0 0 1-39.12 29.27H433.79a40.79 40.79 0 0 1-39.12-29.27M491.91 193.89c-125.75 1.48-227.24 103.88-227.24 230A229.5 229.5 0 0 0 346 599.4c11 9.33 18.14 12.27 28.75 32.12l.56.82c5.82 12.63 7.85 20.93 9.2 29a325.58 325.58 0 0 0 8.74 37.52" />
    </>
  ),
  close: <path d="m55 55 890 890M945 55 55 945" />,
}

const Svg = Component.mb50.svg()
