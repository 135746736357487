import { Fragment, useState, useEffect } from 'react'
import { Component } from './flags'

import { Introduction } from './Introduction'
import { KeyFeatures } from './KeyFeatures'
import { Product } from './Product'
import { Constats } from './Constats'
import { Concept } from './Concept'
import { Ateliers } from './Ateliers'
import { Temoignages } from './Temoignages'
import { Equipe } from './Equipe'
import { Footer } from './Footer'
import { Modal } from './Modal'

import logo_facebook from './_images/logo-facebook.png'
import logo_instagram from './_images/logo-instagram.png'
import logo_linkedin from './_images/logo-linkedin.png'

export const Home = () => {
  const [is_open, set_is_open] = useState(false)

  useEffect(() => {
    // call GET route to measure the traffic
    fetch(`https://localhost:8080/api/record-traffic`)
    // fetch(`https://coschoolingacademy.com/api/record-traffic`)
  }, [])

  return (
    <Fragment>
      <SocialNetworks>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.facebook.com/CoSchoolingAcademy"
        >
          <Logo width={23} src={logo_facebook} />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.instagram.com/coschoolingacademy/"
        >
          <Logo width={21} src={logo_instagram} />
        </a>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://www.linkedin.com/company/coschooling-academy"
        >
          <Logo width={21} src={logo_linkedin} />
        </a>
      </SocialNetworks>
      {is_open && <Modal is_open={is_open} set_is_open={set_is_open} />}

      <Introduction />

      <Main>
        <KeyFeatures set_is_open={set_is_open} />
        <Product />
        <Constats />
        <Concept set_is_open={set_is_open} />
        <Ateliers />
        <Temoignages />
        <Equipe />
      </Main>

      <Footer />
    </Fragment>
  )
}

const Main = Component.pt70.pt0__xs.relative.main()

const SocialNetworks =
  Component.fixed.r20.t20.flex.flex_column.ai_center.zi10.header()
const Logo = Component.c_pointer.mb10.img()

export default Home
