import { Component } from './flags'
import { H2, H5, Section, Text, Cards, Card, Trame } from './components'

export const Temoignages = () => (
  <Section id="temoignages" mt50 mb30>
    <Trame
      style={{
        top: '-10%',
        left: -400,
        transform: 'scale(-1)',
      }}
    />

    <H2 pt0__s>Ils en parlent mieux que nous</H2>

    <Cards>
      {temoignages.map(({ text, author, detail }, index) => (
        <Card w22p={false} w30p key={index}>
          <div>
            <H5>« {text} »</H5>
            <Text fs20 fs25={false}>
              {author}
              <br />
              {detail}
            </Text>
          </div>
        </Card>
      ))}
      <Placeholder />
      <Placeholder />
    </Cards>
  </Section>
)

const Placeholder = Component.h0.w30p.div()

const temoignages = [
  {
    text: `J’ai trouvé les explications claires et j’ai bien aimé la mission du mini reporter et le quiz à la fin.`,
    author: 'Lise, 9 ans',
  },
  {
    text: `Les extraits avec les commentaires des enfants ont facilité l'intérêt de mon fils pour le thème.`,
    author: 'Guillaume',
    detail: 'Papa de Simon, 6 ans',
  },
  {
    text: `Superbe initiative et atelier pilote plus qu'encourageant. Moment très sympa passé à 2 avec mon fils.`,
    author: 'Charlotte',
    detail: 'Maman d’Octave, 7 ans ½',
  },
  {
    text: `C'est un très beau projet, apporter une culture et une ouverture d'esprit aux enfants cela me paraît important. Les supports PDF et le quiz sont bien pensés.`,
    author: 'Aurélie',
    detail: 'Maman de Margot 10 ans',
  },
  {
    text: `J’ai trouvé l’animateur Yannick sympa, il me donne envie de voir d’autres sujets ; moi j’aimerais bien l’astronomie.`,
    author: 'Andrea, 11 ans',
  },
]
