import { Home } from './Home'
import { Audience } from './Audience'
import { Mentions } from './Mentions'
import { Routes, Route, useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const App = () => (
  <>
    <ScrollToTop />
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/mentions-legales" element={<Mentions />} />
      <Route path="admin/audience" element={<Audience />} />
    </Routes>
  </>
)

export default App

const ScrollToTop = () => {
  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return null
}
