import { useState, useEffect } from 'react'
import { Component, Div } from './flags'
import { H2, H4 } from './components'

export const Audience = () => {
  const now = Date.now()
  const [[from, to], set_selected_dates] = useState([now - WEEK, now])

  const [visible_referrers_length, set_visible_referrers_length] =
    useState(referrers_chunk)

  const [metrics, set_metrics] = useState({
    hits: [],
    referrers: [],
    users: [],
  })

  useEffect(() => {
    // call GET route to measure the traffic
    const fetch_metrics = async () => {
      const metrics = await fetch(`https://localhost:8080/api/metrics`)
      // const metrics = await fetch(`https://coschoolingacademy.com/api/metrics`)
      if (metrics.status !== 200) return
      const data = await metrics.json()
      set_metrics(data)
    }

    fetch_metrics()
  }, [])

  const users = metrics.users.map((userHits) =>
    get_matching_hits(userHits, from, to),
  )

  const hits = users.flatMap((e) => e)

  const referrers = Object.entries(metrics.referrers)
    .map(([url, dates]) => {
      const matching_hits = get_matching_hits(dates, from, to)
      return matching_hits.length ? [url, matching_hits] : []
    })
    .filter((d) => d.length)
    .sort((a, b) => b[1].length - a[1].length)

  const visible_referrers = referrers.slice(0, visible_referrers_length)

  return (
    <Page>
      <h2 style={{ opacity: 0.15 }}>coschoolingacademy.com</h2>
      <H2 pt5 pb10 pb40={false} pt40={false} mb80={false} mb60>
        Mesure de l'audience
      </H2>

      <DateInputs>
        Du
        <Input
          defaultValue={getInputFormatDate(from)}
          type="datetime-local"
          max={getInputFormatDate(to)}
          onChange={(event) => {
            set_selected_dates([new Date(event.target.value).getTime(), to])
          }}
        />
        au
        <Input
          defaultValue={getInputFormatDate(to)}
          type="datetime-local"
          min={getInputFormatDate(from)}
          onChange={(event) =>
            set_selected_dates([from, new Date(event.target.value).getTime()])
          }
        />
      </DateInputs>

      <Datas>
        <Data>
          <Div fs90>{hits.length}</Div>
          <Div ph30 text_center>
            Chargements <br />
            de page
          </Div>
        </Data>

        <Data>
          <Div fs90>{users.length}</Div>
          <Div ph30 text_center>
            Utilisateurs <br />
            différents
          </Div>
        </Data>

        <Data>
          <Div fs90>{referrers.length}</Div>
          <Div ph30 text_center>
            URL d'origine <br />
            différentes
          </Div>
        </Data>
      </Datas>

      <Referrers>
        <H4 mb30>Liste des URL d'origine:</H4>

        {!visible_referrers.length && 'No data for the selected dates!'}

        {visible_referrers.map(([url, hitsDates], index) => (
          <Referrer key={url || index}>
            <Div>{url || 'Inconnu'}</Div>
            <div>{hitsDates.length} fois</div>
          </Referrer>
        ))}

        {visible_referrers.length >= visible_referrers_length &&
          referrers.length > visible_referrers_length && (
            <LoadMore
              onClick={() =>
                set_visible_referrers_length(
                  visible_referrers_length + referrers_chunk,
                )
              }
            >
              Voir plus
            </LoadMore>
          )}
      </Referrers>
    </Page>
  )
}

const getInputFormatDate = (timestamp) => {
  const date = new Date(timestamp)
  const year = pad_start(date.getFullYear())
  const month = pad_start(date.getMonth() + 1)
  const day = pad_start(date.getDate())
  const hours = pad_start(date.getHours())
  const mins = pad_start(date.getMinutes())
  return `${year}-${month}-${day}T${hours}:${mins}`
}

const get_matching_hits = (array, from, to) =>
  array.filter((timestamp) => timestamp >= from && timestamp <= to)

const pad_start = (int) => int.toString().padStart(2, '0')

const SECOND = 1000
const MIN = SECOND * 60
const HOUR = MIN * 60
const DAY = HOUR * 24
const WEEK = DAY * 7

const referrers_chunk = 50

const Page = Component.pt70.pb140.ph90.div()
const Datas = Component.flex.jc_between.b20.div()
const Data =
  Component.w30p.h300.flex.flex_column.ai_center.jc_center.bg_light_blue_csa.div()
const DateInputs = Component.flex.ai_center.mb50.div()
const Input = Component.mh5.pv5.fs25.fw200.bt0.br0.bl0.bb.ph20.mh20.input()
const LoadMore =
  Component.c_pointer.w100p.pv10.o70.mt40.bg_light_blue_csa.ba0.ba.button()
const Referrers = Component.mt100.div()
const Referrer = Component.bb.pv10.flex.jc_between.div()
