import { Fragment } from 'react'
import { Component } from './flags'
import { Button, Arrow, Trame } from './components'
import { scroll_to } from './toolbox'

import logo from './_images/co-schooling-academy-logo.png'
import image_intro from './_images/girl-learning-at-home-watching-online-class-on-a-computer.jpg'

export const Introduction = () => (
  <Fragment>
    <Wrapper flex_column__m id="introduction">
      <Trame style={{ top: '50%', left: -425, mixBlendMode: 'multiply' }} />
      <Trame
        r_trame300__s
        r_trame120
        style={{ top: '-45%', mixBlendMode: 'multiply' }}
      />

      <Header w40p w50p__l>
        <Logo
          alt="Co.Schooling Academy Accompagner les 7-15 ans vers le monde de demain logo"
          src={logo}
        />

        <Summary none__m>
          <ul>
            <Anchor id="keys">Infos clés</Anchor>
            <Anchor id="constats">Constats et enjeux de société</Anchor>
            <Anchor id="concept">Le concept Co.Schooling Academy</Anchor>
            <Anchor id="ateliers">Les ateliers videos</Anchor>
            <Anchor id="temoignages">Ils en parlent</Anchor>
            <Anchor id="equipe">Qui sommes-nous</Anchor>
            <Anchor id="contact">Contact</Anchor>
          </ul>
        </Summary>
      </Header>

      <Header w60p w100p__m zi1>
        <Baseline>
          Le complément scolaire de Culture Générale pour vos enfants de 7 à 11
          ans
        </Baseline>

        <Buttons>
          <Button
            w100p__xs
            ph30
            ph25__xxxs
            mr20
            mr0__xs
            mb20__xs
            mb10__xxxs
            onClick={(event) => scroll_to(event, 'keys')}
          >
            Découvrez notre concept
          </Button>
          <PaymentLink
            target="_blank"
            href="https://lydia-app.com/form/payment/phoneform?vendor_token=62161c24333a5277390875"
          >
            <Button w100p__xs ph30 ph25__xxxs mb20__xs mb10__xxxs>
              Commander notre 1er pack !
            </Button>
          </PaymentLink>
        </Buttons>
      </Header>
    </Wrapper>
    <Picture style={{ background: `top / cover url(${image_intro})` }} />
  </Fragment>
)

const Anchor = ({ id, children }) => (
  <li>
    <a href={`#${id}`} onClick={(event) => scroll_to(event, id)}>
      <Arrow />
      <span>{children}</span>
    </a>
  </li>
)

const Wrapper =
  Component.jc_around__xxxs.jc_between__xs.text_center__xs.w100vw.h100vh.flex.pv80.pv40__xs.ph100.ph50__m.ph20__xxxs.pv20__xxxs.relative.header()
const Header =
  Component.zi1.flex.flex_column.ai_center__xs.w100p__xs.jc_between.flex1__m.flex0__xs.header()
const Logo = Component.w330.w300__xs.w280__xxs.w220__xxxs.img()
const Summary = Component.ai_flex_start.flex.flex_column.fs14.nav()
const Baseline =
  Component.max_w400__xs.fs45__s.fs40__xs.fs32__xxs.lh40__xxs.fs30__xxxs.fs25__xxxxs.lh35__xxxxs.lh38__xxxs.blue_csa.max_w500__d.w70p.w100p__l.w90p__m.mt100.mt0__m.fs55.lh80.lh70__m.lh50__xs.fw500.h1()
const Picture =
  Component.block__xs.relative__xs.w35p.w40p__m.w100vw__xs.h400__xs.r0__xs.t0__xs.h90p.h40vh__xs.absolute.t100.t60__m.r100.r60__m.div()
const Buttons =
  Component.flex_column__xs.ai_center__xs.mb30__xs.mb10__xxxs.mt50__xs.mt30__xxxs.flex.div()
const PaymentLink = Component.mb0.a()
