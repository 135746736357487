import { Div } from './flags'
import { H2, Text, Section, Paragraph, Article, Quote } from './components'
import { Trame } from './components'

export const Constats = () => (
  <Section>
    <Trame
      style={{
        top: '55%',
        right: -400,
        transform: 'scaleX(-1) rotate(-160deg)',
      }}
    />

    <H2 pt10__s id="constats">
      Constats et enjeux
      <br /> de société
    </H2>

    <Article>
      <Paragraph>
        <Text>
          Les jeunes vont devoir faire face à de multiples défis au cours du XXI
          <sup>è</sup> siècle : climatiques, sociologiques, économiques,
          démographiques...
          <br /> Ces défis vont entrainer et entrainent déjà des tensions dans
          nos sociétés, que seules les personnes possédant des connaissances et
          une capacité d’analyse peuvent comprendre et apaiser. <br />
          Afin de les préparer le mieux possible à affronter ces enjeux, il est
          fondamental de susciter leur curiosité et développer leur esprit
          critique. <br />
          Ceux qui ont des connaissances, qui peuvent comparer, analyser et
          faire le tri dans les informations, auront une longueur d’avance.
        </Text>
      </Paragraph>
      <Paragraph mt60__m>
        <Quote>
          « L'éducation est l'arme la plus puissante pour changer le monde. »
          <br />
          <Div mt30>— Nelson Mandela</Div>
        </Quote>
      </Paragraph>
    </Article>
  </Section>
)
