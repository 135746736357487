import { H2, H3, Text, Section, Paragraph, Article } from './components'
import { Image, NewsletterSubscribe, Trame } from './components'
import { Component } from './flags'

import image_1 from './_images/kid-learning-at-home-watching-online-class-on-a-computer.jpg'
import image_2 from './_images/mother-helping-son-working-home-on-computer.jpg'
import image_3 from './_images/girl-working-watching-computer-and-writing.jpg'

export const Concept = ({ set_is_open }) => (
  <Section>
    <H2 id="concept">Le concept</H2>

    <Article mb120 mb100__m mb60__xxxs relative>
      <Trame style={{ top: '50%', left: -500, transform: 'scale(-1)' }} />

      <Image
        max_h270__xxs
        title="kid learning at home watching an online class on a computer"
        style={{ background: `top / cover url(${image_1})` }}
      />

      <Paragraph mt60__m>
        <H3>Des ateliers pour devenir un citoyen du monde de demain</H3>
        <Text>
          La Co.schooling academy propose des ateliers éducatifs vidéo de 15
          minutes, pour les 7-11 ans, pour apprendre et découvrir tout ce qu’ils
          n’apprennent pas à l’école. Chaque semaine, directement de chez eux et
          sans se déplacer, vos enfants vont développer leur{' '}
          <strong>Culture Générale</strong> selon plusieurs thèmes que vous
          aurez choisi avec eux. <br />
          <strong>C'est un véritable complément du système scolaire</strong>.
        </Text>
      </Paragraph>
    </Article>

    <Article mb120 mb100__m mb60__xxxs relative>
      <Trame
        style={{
          top: '60%',
          right: -500,
          transform: 'scale(-1) rotate(-160deg)',
        }}
      />

      <Paragraph mt60__m order2__m>
        <H3>La Co.schooling Academy accompagne également les parents</H3>
        <Text>
          Avec un guide pédagogique complet et des conférences en ligne animées
          par des experts connus, sur des sujets de parentalité, d’actualité ou
          de développement personnel.
        </Text>
      </Paragraph>

      <Image
        order1__s
        title="mother helping her son working at home on a computer"
        style={{ background: `top / cover url(${image_2})` }}
      />
    </Article>

    <Article>
      <Image
        title="girl working while watching her computer and writing.jpg"
        style={{ background: `top / cover url(${image_3})` }}
      />
      <Paragraph mt60__m>
        <H3>
          Pour quelles raisons abonner votre enfant à la Co.schooling Academy?
        </H3>
        <ul style={{ paddingLeft: '42px' }}>
          <li>
            pour développer sa curiosité et son envie d’apprendre <br />
          </li>
          <li>
            pour compléter ce qu’il n’aborde pas à l'école <br />
          </li>
          <li>
            pour qu’il gagne en confiance <br />
          </li>
          <li>
            pour qu’il développe son esprit critique <br />
          </li>
          <li>
            pour le conforter dans les valeurs citoyennes <br />
          </li>
        </ul>

        <Note mt40>Plusieurs formules d’abonnement à partir de 39€</Note>

        <NewsletterSubscribe
          mt50
          mt80={false}
          set_is_open={set_is_open}
          w100p
        />
      </Paragraph>
    </Article>
  </Section>
)

const Note = Component.fs18.p()
