import { H2, H3, H4, Text, Section, Paragraph, Article } from './components'
import { Button, Cards, Card } from './components'
import { Component } from './flags'

import extrait from './_images/co-schooling-academy-extrait.mp4'

export const Ateliers = () => (
  <Section id="ateliers" mt100 mt40__xxxs pv40 bg_blue_csa white>
    <H2 pt20__s white b_white b_blue_csa={false} blue_csa={false}>
      Les ateliers vidéo
    </H2>

    <Article mb80 mb60__m>
      <H3 white blue_csa={false}>
        4 thématiques tournées vers la Culture générale et le développement de
        l’esprit critique
      </H3>
      <Cards>
        {themes.map(({ title, description }) => (
          <Theme key={title} title={title} description={description} />
        ))}
      </Cards>
    </Article>

    <Article flex_column jc_center ai_center mb100 mb60__m mb50__xxxs>
      <Paragraph w100p>
        <H3 white blue_csa={false}>
          Une pédagogie active et inversée
        </H3>
        <Text>
          Un format original pour nos ateliers vidéo, basé sur une pédagogie
          active et inversée : Vos enfants ne seront pas simplement des
          spectateurs passifs, leur participation sera favorisée pendant les
          vidéos, afin de les rendre actifs dans leurs apprentissages. <br />
        </Text>

        <H3 mt80 white blue_csa={false}>
          Découvrez un extrait de notre premier atelier ci-dessous !
        </H3>
      </Paragraph>

      <Video
        controlsList="nodownload"
        controls
        title="Co.schooling Academy atelier vidéo extrait"
      >
        <source src={extrait} type="video/mp4" />
      </Video>
      <PaymentLink
        href="https://lydia-app.com/form/payment/phoneform?vendor_token=62161c24333a5277390875"
        target="_blank"
        rel="noreferrer"
      >
        <Button w100p fs15 ph40 bg_white bg_blue_csa={false} blue_csa fw600>
          Commander le pack !
        </Button>
      </PaymentLink>
    </Article>
  </Section>
)

const Theme = ({ title, description }) => {
  const file_name = title
    .toLowerCase()
    .replaceAll('&', 'et')
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .split(' ')
    .join('-')

  return (
    <Card w47p__l>
      <div>
        <H4>{title}</H4>
        <Text fs20 fs25={false}>
          {description}
        </Text>
      </div>
      <Buttons>
        {title === 'Comprendre le monde de demain' && (
          <a
            href="https://lydia-app.com/form/payment/phoneform?vendor_token=62161c24333a5277390875"
            target="_blank"
            rel="noreferrer"
          >
            <Button w100p>Recevoir mon pack</Button>
          </a>
        )}
        <a
          href={`/documents/${file_name}.pdf`}
          target="_blank"
          rel="noreferrer"
        >
          <Button w100p>Découvrir le thème</Button>
        </a>
      </Buttons>
    </Card>
  )
}

const Buttons = Component.mt40.w100p.div()
const PaymentLink = Component.mb0.w100p.a()
const Video = Component.mt30.mb80.w100p.video()

const themes = [
  {
    title: 'Comprendre le monde de demain',
    description: `Sensibiliser leur intérêt pour les défis qu’ils devront relever: environnement, numérique, société`,
  },
  {
    title: 'Médias & actualités',
    description: `Comprendre ce que sont les médias, décrypter des sujets d’actualités et se faire une opinion`,
  },
  {
    title: 'Art & culture',
    description: `S’initier et développer leur culture artistique`,
  },
  {
    title: 'Histoire du monde & citoyenneté',
    description: `Compléter les notions de l’Histoire et du parcours citoyen`,
  },
]
