import { Component } from './flags'
import { H3, Button } from './components'

import image from './_images/girl-smiling-next-to-an-earth-globe.png'

export const Product = () => (
  <Wrapper>
    <Description>
      <Header>
        Découvrez notre 1<sup>er</sup> pack d’ateliers éducatifs
      </Header>
      <H3 white blue_csa={false} fs40 lh52 lh65={false} fs50={false} mt35 fw400>
        Comprendre le monde de demain : je protège ma planète
      </H3>
      <Span>Contenu du pack:</Span>
      <Items>
        <ItemsWrapper mr30>
          <Item>• 10 ateliers vidéo de 15 minutes</Item>
          <Item>• Le guide pédagogique</Item>
          <Item>• Les fiches récap </Item>
          <Item>• Les fiches de vocabulaire </Item>
        </ItemsWrapper>

        <ItemsWrapper>
          <Item>• Les fiches expériences </Item>
          <Item>• Un planning conseillé</Item>
          <Item>• Les quiz </Item>
          <Item>• Les fiches d’activités pour aller plus loin</Item>
        </ItemsWrapper>
      </Items>
    </Description>
    <Right>
      <Image
        title="girl smiling next to an earth globe"
        style={{ background: `center / cover url(${image})` }}
      />
      <Price style={{ bottom: 70 }}>39€</Price>
      <PaymentLink
        href="https://lydia-app.com/form/payment/phoneform?vendor_token=62161c24333a5277390875"
        target="_blank"
        rel="noreferrer"
      >
        <Button bg_blue_csa={false} blue_csa fw600 fs14 w100p>
          COMMANDER
        </Button>
      </PaymentLink>
    </Right>
  </Wrapper>
)

const Wrapper =
  Component.flex.ph100.ph50__s.pv100.pv50__s.bg_blue_csa.flex_column__s.mb30.section()
const Header = Component.pv10.ph25.ba.white.b_white.bw2.fw400.header()
const Description =
  Component.white.flex1.pr50.pr0__s.flex.flex_column.ai_flex_start.div()
const Span = Component.fw400.bb.b_white.div()
const Items = Component.flex.flex_column__xs.mt15.div()
const ItemsWrapper = Component.w100p__xs.div()
const Item = Component.fs22.div()

const Right =
  Component.mt60__s.relative.w30p.w100p__s.flex.flex_column.ai_center.jc_flex_end.div()
const Image = Component.mb90.w100p.h70p.min_h250.max_h270__xxs.max_h350__m.div()
const Price =
  Component.fw300.bg_light_blue_csa.blue_csa.absolute.h120.w120.b_rad50p.flex.ai_center.jc_center.fs35.fw600.div()
const PaymentLink = Component.w100p.mb0.a()
