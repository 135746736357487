import { Fragment, useState, useEffect } from 'react'
import { Component } from './flags'
import { Button } from './components'
import { Icon } from './icons'

export const Modal = ({ is_open, set_is_open }) => {
  const [wrapper, set_wrapper] = useState(null)
  const [email, set_email] = useState({ value: '', sent: false, feedback: '' })

  const handle_click_outside = (event) => {
    if (event.target !== wrapper) return
    set_is_open(false)
  }

  const handle_keydown = (event) => {
    if (event.key !== 'Escape') return
    set_is_open(false)
  }

  return (
    <Wrapper
      tabIndex="0"
      onClick={handle_click_outside}
      onKeyDown={handle_keydown}
      elemRef={set_wrapper}
    >
      <Content>
        <Close onClick={() => set_is_open(false)}>
          <Icon width={30} mb0 icon="close" />
        </Close>
        {email.sent ? (
          <div>
            Merci de votre soutien,
            <br />
            nous sommes ravis de vous compter parmi nous, à très vite !
          </div>
        ) : (
          <Form set_is_open={set_is_open} email={email} set_email={set_email} />
        )}
      </Content>
    </Wrapper>
  )
}

const Form = ({ email, set_email, set_is_open }) => {
  const [input, set_input] = useState(null)

  useEffect(() => {
    if (!input) return
    input.focus()
  }, [input])

  const handle_submit = async () => {
    const submit_email = async () => {
      // call POST route to save the email adress in the file
      const response = await fetch(
        `https://localhost:8080/api/subscribe`,
        // `https://coschoolingacademy.com/api/subscribe`,
        {
          method: 'POST',
          body: email.value,
        },
      )
      if (response.status !== 200) throw Error(response.statusText)
    }

    try {
      await submit_email()
      set_email({ ...email, sent: true })
      setTimeout(() => set_is_open(false), 3000)
    } catch (error) {
      console.log(error)
      set_email({
        ...email,
        feedback: `Une erreur s'est produite, nous n'avons pas pu récupérer votre adresse mail.`,
      })
    }
  }

  return (
    <Fragment>
      Laissez-nous votre adresse mail pour suivre nos dernières actualités !
      <EmailInput
        elemRef={set_input}
        type="email"
        placeholder="Votre adresse mail"
        onInput={(event) => {
          const { value } = event.target
          const is_valid_email = validate_email(value)

          if (is_valid_email) {
            set_email({ feedback: '', value })
          } else {
            set_email({
              ...email,
              value: '',
              feedback: value.length
                ? 'Veuillez renseigner une adresse email conforme'
                : '',
            })
          }
        }}
      />
      <Button
        c_pointer={email.value}
        disabled={!email.value}
        onClick={handle_submit}
        mt20
        w100p
      >
        S'inscrire
      </Button>
      <Feedback>{email.feedback}</Feedback>
    </Fragment>
  )
}

const validate_email = (email) => String(email).toLowerCase().match(email_regex)
const email_regex =
  /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i

const Wrapper =
  Component.t0.flex.ai_center.jc_center.l0.w100vw.h100vh.fixed.zi10.div()
const Content =
  Component.shadow_a_s.pa80.pa50__m.pt70__m.relative.bg_white.w60p.flex__xs.flex_column__xs.jc_center.w100p__xs.h100p__xs.div()
const Close = Component.c_pointer.ba0.bg_none.absolute.r30.t30.button()
const EmailInput = Component.ba.mt50.pa10.w100p.fs20.input()
const Feedback = Component.mt15.orange6.fs12.h20.text_center.p()
