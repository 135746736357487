import { atomizify, flagify } from 'atomizify'

// create all the atomic CSS classes with atomizify library
atomizify({
  custom_classes: {
    blue_csa: 'color: #2b475e',
    bg_blue_csa: 'background-color: #2b475e',
    b_blue_csa: 'border-color: #2b475e',
    light_blue_csa: 'color: #cad9ec',
    bg_light_blue_csa: 'background-color: #cad9ec',
    green_csa: 'color: #95c11f',
    bg_green_csa: 'background-color: #95c11f',
    b_green_csa: 'border-color: #95c11f',
    bg_light_green_csa: 'background-color: rgba(149, 193, 31, 0.8)',
    w22p: 'width: 22%',
    w27p: 'width: 27%',
    w47p: 'width: 47%',
    lh35: 'line-height: 35px',
    lh38: 'line-height: 38px',
    lh40: 'line-height: 40px',
    lh50: 'line-height: 50px',
    lh52: 'line-height: 52px',
    lh55: 'line-height: 55px',
    lh65: 'line-height: 65px',
    lh70: 'line-height: 70px',
    lh80: 'line-height: 80px',
    fs32: 'font-size: 32px',
    fs35: 'font-size: 35px',
    fs40: 'font-size: 40px',
    fs45: 'font-size: 45px',
    fs55: 'font-size: 55px',
    r_trame300: 'right: -300px',
    r_trame120: 'right: -120px',
  },
  media_queries: {
    __l: {
      query: 'max-width: 1400px',
      description: 'medium screens',
    },
    __m: {
      query: 'max-width: 1100px',
      description: 'medium screens',
    },
    __s: {
      query: 'max-width: 950px',
      description: 'small screens',
    },
    __xs: {
      query: 'max-width: 810px',
      description: 'extra small screens',
    },
    __xxs: {
      query: 'max-width: 600px',
      description: 'extra extra small screens',
    },
    __xxxs: {
      query: 'max-width: 400px',
      description: 'extra extra extra small screens',
    },
    __xxxxs: {
      query: 'max-width: 320px',
      description: 'extra extra extra extra small screens',
    },
    __d: {
      query: 'min-width: 1100px',
      description: 'desktop screens',
    },
  },
})

// export Component to create styled components receiving CSS flags
export const { Component, Div, Span } = flagify()
