import { useState } from 'react'
import { Component, Div } from './flags'
import { H2, Button, Trame } from './components'
import { Link } from 'react-router-dom'

export const Footer = () => {
  const [message, set_message] = useState(false)

  return (
    <Wrapper id="contact">
      <Trame
        style={{
          top: '0',
          left: -500,
          transform: 'scale(-1)',
        }}
      />

      <H2 mb60__xs>Contact</H2>

      <Div flex flex_column__xs>
        <Details>
          <div>
            Tel. : +33 7 86 96 79 89
            <br />
            Mail : marion@coschoolingacademy.com
          </div>
          <Div mt30__xs>
            À la Co.schooling Academy, les jeunes prennent une longueur d'avance
            pour construire leur futur !<br />
            <br />© Co.schooling Academy 2021 —{' '}
            <Link className="mentions" to="/mentions-legales">
              Mentions légales
            </Link>
          </Div>
          <Div>
            Design & development by{' '}
            <Href target="_blank" href="https://marie.studiodev.xyz/">
              Marie Malarme
            </Href>
          </Div>
        </Details>
        {message.sent ? (
          <Success>{message.text}</Success>
        ) : (
          <Form
            onSubmit={async (event) => {
              event.preventDefault()
              const email_address = event.target[0].value
              const message = event.target[1].value

              const submit_message = async () => {
                // call POST route to send the message to marion
                const response = await fetch(
                  `https://localhost:8080/api/send-message`,
                  // `https://coschoolingacademy.com/api/send-message`,
                  {
                    method: 'POST',
                    body: `Vous avez reçu un message via le formulaire de contact de la landing page:\n\nDe: ${email_address}\nMessage: "${message}"`,
                  },
                )
                if (response.status !== 200) throw Error(response.statusText)
              }

              try {
                await submit_message()
                set_message({
                  submitted: true,
                  sent: true,
                  text: 'Merci pour votre message! Nous vous répondrons aussi vite que possible.',
                })
              } catch (error) {
                console.log(error)
                set_message({
                  submitted: true,
                  sent: false,
                  text: "Une erreur s'est produite! Nous n'avons malheureusement pas pu transmettre votre message.",
                })
              }
            }}
          >
            <Input
              required
              name="email-address"
              type="email"
              placeholder="Votre adresse mail"
            />
            <Textarea required name="message" placeholder="Votre message" />
            <Error>{message.submitted && !message.sent && message.text}</Error>
            <Button w100p mt30>
              Envoyer
            </Button>
          </Form>
        )}
      </Div>
    </Wrapper>
  )
}

const Wrapper =
  Component.zi1.pt100.pt30__m.pb100.pb80__m.pb50__xxs.ph100.ph50__s.ph30__xxxs.relative.fs20.fs15__xxs.footer()
const Details =
  Component.order2__xs.w100p__xs.w50p.blue_csa.mr40.flex.flex_column.jc_between.div()
const Form = Component.w100p__xs.w50p.ml40.ml0__xs.mb50__xs.form()
const Input =
  Component.b_rad3.bg_none.blue_csa.w100p.fs17.pv10.ph15.mb20.input()
const Textarea =
  Component.b_rad3.bg_none.blue_csa.w100p.fs17.pv10.ph15.h150.textarea()
const Success = Component.green_csa.fs30.mb50.fw400.p()
const Error = Component.orange6.mt10.p()
const Href = Component.bb.fs20.inline.c_pointer.blue_csa.b_blue_csa.a()
