import { Component } from './flags'

// text
export const H2 =
  Component.fw400.w100p.bb.bw2.b_blue_csa.pb40.pb25__xxxs.fs60.fs55__m.fs45__xxxs.lh55__xxxs.lh80__m.mb80.mb40__xxxs.pt40.blue_csa.h2()
export const H3 =
  Component.lh65.fw300.blue_csa.fs50.fs45__m.fs32__xxxs.lh40__xxxs.mb40.h3()
export const H4 = Component.fw500.lh38.blue_csa.fs30.fs25__m.mb20.h4()
export const H5 = Component.fw400.lh38.blue_csa.fs30.fs28__m.mb20.h4()
export const Text = Component.fs20__xxxs.p()
export const Quote =
  Component.lh65.fw300.blue_csa.fs50.fs45__m.fs32__xxxs.lh40__xxxs.zi1.relative.blockquote()

// blocks
export const Section = Component.ph100.ph50__s.ph40__xxxs.zi1.relative.section()
export const Article = Component.flex.flex_wrap.jc_between.mb50.article()
export const Paragraph = Component.w45p.w100p__m.div()

// call to actions
export const Button =
  Component.uppercase.ls3.lh15.flex_shrink0.c_pointer.shadow_a_s.bg_blue_csa.white.ba0.ph20.pv15.fs10.fw400.b_rad2.button()
const NewsletterButton =
  Component.uppercase.mt80.ls3.lh23.flex_shrink0.c_pointer.shadow_a_s.bg_blue_csa.white.ba0.ph45.pv15.fs13.fw400.b_rad2.button()
export const NewsletterSubscribe = ({ set_is_open, ...style }) => (
  <NewsletterButton onClick={() => set_is_open(true)} {...style}>
    Je veux en savoir plus !
  </NewsletterButton>
)

// cards
export const Cards = Component.w100p.mt30.flex.jc_between.flex_wrap.article()
export const Card =
  Component.flex.flex_column.jc_between.blue_csa.w22p.w47p__m.w100p__xs.bg_light_blue_csa.ph30.pv25.mb40.article()

// visuals
export const Image = Component.w45p.w100p__m.h500.h400__m.div()
export const Svg = Component.absolute.o50.svg()

export const Arrow = ({ width = 20, color = '#2b475e', stroke_width = 6 }) => (
  <svg width={width} viewBox="0 0 130 130" style={{ marginRight: 15 }}>
    <path
      fill="none"
      stroke={color}
      strokeWidth={stroke_width}
      d="M73 18l48 47-48 48M6 65h114"
    />
  </svg>
)

export const Trame = ({ style, ...props }) => (
  <Svg
    className="trame"
    viewBox="0 0 390 515"
    xmlns="http://www.w3.org/2000/svg"
    style={{ zIndex: -1, ...style }}
    {...props}
  >
    {Object.entries(trame_paths).map(([path, color], index) => (
      <path key={index} fill={color} d={path} />
    ))}
  </Svg>
)

const trame_paths = {
  'M257.8 419.07c4.71-10.5 13.41-19.32 24.17-23.38 6.9-2.6 14.36-3.23 21.72-3.8s15-1.13 22.24.43c7.94 1.71 15.12 5.84 22.13 9.95 4.48 2.63 9.06 5.35 12.39 9.35 3.82 4.58 5.67 10.45 7.44 16.15 1.84 5.92 3.7 12 3.55 18.16-.12 5.63-1.9 11.08-3.9 16.35-6.44 17-16.79 34.21-33.79 40.62-10 3.78-21.17 3.28-31.67 1.24-12.94-2.52-25.75-7.5-35.33-16.54-8.62-8.12-14.38-20-13.56-32 .81-12.41-.73-24.6 4.61-36.53Z': `#95c11f`,
  'M175.5 49.31a91.2 91.2 0 0 1 12.62-6.79c5.88-2.39 12.13-3.52 18.28-5.09 28.85-7.08 55.46-22.41 84.85-26.78L294 21.81a8.15 8.15 0 0 1 .3 4c-.53 1.94-2.4 3.18-4.14 4.21-34.36 20.27-74.44 28.41-110.79 44.81-4.48 2-9.58 4.19-14.13 2.29s-8.9-10.08-7.57-14.93c1.57-5.8 12.89-9.9 17.83-12.88Z': `#95c11f`,
  'M11.66 159.06c-3.3 5.26-3.84 12-3.72 18.46a20.59 20.59 0 0 0 1.33 8c1.27 2.85 3.66 4.87 6.08 6.57 5.06 3.53 10.81 6.12 16.78 6.21 7.61.12 15.17-4.08 19.63-10.9A29.44 29.44 0 0 0 55 163.31a21.87 21.87 0 0 0-3-6.23c-8.81-12.49-32.38-10.69-40.34 1.98Z': `#95c11f`,
  'm121.55 183.49 16-20.61c9.55-12.33 19.1-24.67 29.11-36.63 1.78-2.13 3.64-4.3 6.09-5.61 4.35-2.32 9.7-1.51 14.37.08 8.45 2.88 15.91 8 23.87 12.05 16.63 8.4 35.35 11.82 52.34 19.45 8.16 3.67 16.88 9.88 17 18.82a28 28 0 0 1-2 9c-7.47 22.8-9 71.68-8.95 72.63a132.8 132.8 0 0 1-14.47 60.4c-20.82-9.8-39.54-23.47-58-37.18q-30.36-22.53-60.45-45.4-7.47-5.64-14.91-11.3c-3.44-2.61-11.09-6.31-13.16-10.14-3.88-7.14 8.83-19.97 13.16-25.56ZM32.49 363.25c-2 15.95 3.95 29.95 14.8 41.8 6.79 7.42 33.48 21.85 42.8 25.63 7.49 3 33.08 14.71 41.16 14.72 16 0 31.09-9.19 41.23-21.61s14.64-23.14 19-38.57a81 81 0 0 0-16.6-7.56c-5.09 15-13.58 32.23-26.61 41.31s-46.56.54-60.49-7.08-39.69-29.89-38.25-47.2c.84-10.1 10.93-32 16-40.49-2.83-3.36-5.33-4.69-11.32-9.51-20.45 24.62-20.31 37.58-21.72 48.56Z': `#2b475e`,
  'M138.25 313.52c-2.46 3.54-2.86 8.09-2.77 12.39a12.59 12.59 0 0 0 1 5.38 11.68 11.68 0 0 0 4.53 4.4 24.38 24.38 0 0 0 12.49 4.17 17.76 17.76 0 0 0 18-17.51 18.07 18.07 0 0 0-.95-6 14.13 14.13 0 0 0-2.22-4.18c-6.6-8.35-24.16-7.17-30.08 1.35Z': `#95c11f`,
}
