import { Link } from 'react-router-dom'
import { H2 } from './components'
import { Component, Div } from './flags'

const Subtitle = Component.mt50.fw400.fs30.div()
const Definition = Component.fw400.span()

export const Mentions = () => (
  <Wrapper id="mentions">
    <Link className="back-home" to="/">
      <Div fs20>{'<'} Retour au site</Div>
    </Link>

    <H2 pt20 pt40={false} pb40={false} pb20 mb50 mb80={false}>
      Mentions légales
    </H2>

    <Subtitle mb20>Définitions</Subtitle>
    <Div mt15>
      <Definition>Site:</Definition> www.coschoolingacademy.com
    </Div>

    <Div mt15>
      <Definition>Client:</Definition> tout professionnel ou personne physique
      capable au sens des articles 1123 et suivants du Code civil, ou personne
      morale, qui visite le Site objet des présentes conditions générales.
    </Div>

    {/*    <Div mt15>
      <Definition>Prestations et Services:</Definition> Co.schooling Academy met
      à disposition des Clients:
    </Div>*/}

    <Div mt15>
      <Definition>Contenu:</Definition> ensemble des éléments constituants
      l’information présente sur le Site, notamment textes — images — vidéos.
    </Div>

    {/*    <Div mt15>
      <Definition>Informations clients:</Definition> ci-après dénommé «
      Information (s) » qui correspondent à l’ensemble des données personnelles
      susceptibles d’être détenues par Co.schooling Academy pour la gestion de
      votre compte, de la gestion de la relation client et à des fins d’analyses
      et de statistiques.
    </Div>*/}

    <Div mt15>
      <Definition>Utilisateur:</Definition> internaute se connectant, utilisant
      le site susnommé.
    </Div>

    {/*    <Div mt15>
      <Definition>Informations personnelles:</Definition> « les informations qui
      permettent, sous quelque forme que ce soit, directement ou non,
      l'identification des personnes physiques auxquelles elles s'appliquent »
      (article 4 de la loi n° 78-17 du 6 janvier 1978).
    </Div>*/}

    <Div>
      Les termes « données à caractère personnel », « personne concernée », «
      sous-traitant » et « données sensibles » ont le sens défini par le
      Règlement Général sur la Protection des Données (RGPD: n° 2016-679).
    </Div>

    <Div>
      <Subtitle>1. Présentation du site internet</Subtitle>
      En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la
      confiance dans l'économie numérique, il est précisé aux utilisateurs du
      site internet Co.schooling Academy l'identité des différents intervenants
      dans le cadre de sa réalisation et de son suivi:
      <Div mt15>
        <Definition>Propriétaire:</Definition> SAS Co.schooling Academy —
        Capital social de 500€ — 16 rue Frédéric Bazille 34000 Montpellier
      </Div>
      <Div mt15>
        <Definition>Responsable publication:</Definition> Marion Guigue —
        marionguigue@hotmail.com
        <br />
        Le responsable publication est une personne physique ou une personne
        morale.
      </Div>
      <Div mt15>
        <Definition>Webmaster:</Definition> Marie Malarme —
        malarmemarie@gmail.com —{' '}
        <Href target="_blank" href="https://marie.studiodev.xyz">
          Site internet
        </Href>
      </Div>
      <Div mt15>
        <Definition>Hébergeur:</Definition> HOSTINGER INTERNATIONAL LTD — 61
        Lordou Vironos Street, 6023 Larnaca
        <br />
        <Href target="_blank" href="https://www.hostinger.fr/contact">
          Site de Hostinger
        </Href>
      </Div>
      {/*      <Div mt15>
        <Definition>Délégué à la protection des données:</Definition> Marion
        Guigue — marionguigue@hotmail.com
      </Div>*/}
      <Div mt40>
        Ce modèle de mentions légales est proposé par le{' '}
        <Href
          target="_blank"
          href="https://fr.orson.io/1371/generateur-mentions-legales"
          title="générateur gratuit de mentions légales pour un site internet"
        >
          générateur gratuit de mentions légales pour un site internet
        </Href>
        .
      </Div>
    </Div>

    <Div>
      <Subtitle>
        2. Conditions générales d’utilisation du site et des services proposés
      </Subtitle>
      Le Site constitue une œuvre de l’esprit protégée par les dispositions du
      Code de la Propriété Intellectuelle et des Réglementations Internationales
      applicables.
      <br />
      Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour
      son propre compte tout ou partie des éléments ou travaux du Site.
      <br />
      L’utilisation du site Co.schooling Academy implique l’acceptation pleine
      et entière des conditions générales d’utilisation ci-après décrites. Ces
      conditions d’utilisation sont susceptibles d’être modifiées ou complétées
      à tout moment, les utilisateurs du site Co.schooling Academy sont donc
      invités à les consulter de manière régulière.
      <br />
      Ce site internet est normalement accessible à tout moment aux
      utilisateurs. Une interruption pour raison de maintenance technique peut
      être toutefois décidée par Co.schooling Academy, qui s’efforcera alors de
      communiquer préalablement aux utilisateurs les dates et heures de
      l’intervention.
      <br />
      De la même façon, les mentions légales peuvent être modifiées à tout
      moment: elles s’imposent néanmoins à l’utilisateur qui est invité à s’y
      référer le plus souvent possible afin d’en prendre connaissance.
    </Div>

    <Div>
      <Subtitle>3. Description des services fournis</Subtitle>
      Le site internet Co.schooling Academy a pour objet de fournir une
      information concernant l’ensemble des activités de la société.
      <br />
      Co.schooling Academy s’efforce de fournir sur le site Co.schooling Academy
      des informations aussi précises que possible. Toutefois, il ne pourra être
      tenu responsable des oublis, des inexactitudes et des carences dans la
      mise à jour, qu’elles soient de son fait ou du fait des tiers partenaires
      qui lui fournissent ces informations.
      <br />
      Toutes les informations indiquées sur le site Co.schooling Academy sont
      données à titre indicatif, et sont susceptibles d’évoluer. Par ailleurs,
      les renseignements figurant sur le site Co.schooling Academy ne sont pas
      exhaustifs. Ils sont donnés sous réserve de modifications ayant été
      apportées depuis leur mise en ligne.
    </Div>

    <Div>
      <Subtitle>
        4. Limitations contractuelles sur les données techniques
      </Subtitle>
      Le site utilise la technologie JavaScript.
      <br />
      Le site Internet ne pourra être tenu responsable de dommages matériels
      liés à l’utilisation du site. De plus, l’utilisateur du site s’engage à
      accéder au site en utilisant un matériel récent, ne contenant pas de virus
      et avec un navigateur de dernière génération mis-à-jour.
      <br />
      Le site Co.schooling Academy est hébergé chez un prestataire sur le
      territoire de l’Union Européenne conformément aux dispositions du
      Règlement Général sur la Protection des Données (RGPD: n° 2016-679).
      L’objectif est d’apporter une prestation qui assure le meilleur taux
      d’accessibilité. L’hébergeur assure la continuité de son service 24 Heures
      sur 24, tous les jours de l’année. Il se réserve néanmoins la possibilité
      d’interrompre le service d’hébergement pour les durées les plus courtes
      possibles notamment à des fins de maintenance, d’amélioration de ses
      infrastructures, de défaillance de ses infrastructures ou si le Site
      génère un trafic réputé anormal.
      <br />
      Co.schooling Academy et l’hébergeur ne pourront être tenus responsables en
      cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou
      du matériel informatique et de téléphonie lié notamment à l’encombrement
      du réseau empêchant l’accès au serveur.
    </Div>

    <Div>
      <Subtitle>5. Propriété intellectuelle et contrefaçons</Subtitle>
      Co.schooling Academy est propriétaire des droits de propriété
      intellectuelle et détient les droits d’usage sur tous les éléments
      accessibles sur le site internet, notamment les textes, images,
      graphismes, logos, vidéos, icônes et sons.
      <br />
      Toute reproduction, représentation, modification, publication, adaptation
      de tout ou partie des éléments du site, quel que soit le moyen ou le
      procédé utilisé, est interdite, sauf autorisation écrite préalable de:
      Co.schooling Academy.
      <br />
      Toute exploitation non autorisée du site ou de l’un quelconque des
      éléments qu’il contient sera considérée comme constitutive d’une
      contrefaçon et poursuivie conformément aux dispositions des articles
      L.335-2 et suivants du Code de Propriété Intellectuelle.
    </Div>

    <Div>
      <Subtitle>6. Limitations de responsabilité</Subtitle>
      Co.schooling Academy agit en tant qu’éditeur du site.
      <br />
      Co.schooling Academy est responsable de la qualité et de la véracité du
      Contenu qu’il publie.
      <br />
      Co.schooling Academy ne pourra être tenu responsable des dommages directs
      et indirects causés au matériel de l’utilisateur, lors de l’accès au site
      internet Co.schooling Academy, et résultant soit de l’utilisation d’un
      matériel ne répondant pas aux spécifications indiquées au point 4, soit de
      l’apparition d’un bug ou d’une incompatibilité.
      <br />
      Co.schooling Academy ne pourra également être tenu responsable des
      dommages indirects (tels par exemple qu’une perte de marché ou perte d’une
      chance) consécutifs à l’utilisation du site Co.schooling Academy.
      <br />
      Des espaces interactifs (possibilité de poser des questions dans l’espace
      contact) sont à la disposition des utilisateurs. Co.schooling Academy se
      réserve le droit de supprimer, sans mise en demeure préalable, tout
      contenu déposé dans cet espace qui contreviendrait à la législation
      applicable en France, en particulier aux dispositions relatives à la
      protection des données. Le cas échéant, Co.schooling Academy se réserve
      également la possibilité de mettre en cause la responsabilité civile et/ou
      pénale de l’utilisateur, notamment en cas de message à caractère raciste,
      injurieux, diffamant, ou pornographique, quel que soit le support utilisé
      (texte, photographie...).
    </Div>

    <Div>
      <Subtitle>7. Gestion des données personnelles</Subtitle>
      Le Site Co.schooling Academy ne collecte pas de données personnelles.
      <br /> Le Client est informé des réglementations concernant la
      communication marketing, la loi du 21 Juin 2014 pour la confiance dans
      l’Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi
      que du Règlement Général sur la Protection des Données (RGPD: n°
      2016-679).
      {/*<Subtitle>
        7.1 Responsables de la collecte des données personnelles
      </Subtitle>
      Pour les Données Personnelles collectées dans le cadre de la création du
      compte personnel de l’Utilisateur et de sa navigation sur le Site, le
      responsable du traitement des Données Personnelles est Co.schooling
      Academy. Co.schooling Academy est représenté par Marion Guigue, son
      représentant légal.<br />
      En tant que responsable du traitement des données qu’il collecte,
      Co.schooling Academy s’engage à respecter le cadre des dispositions
      légales en vigueur. Il lui appartient notamment au Client d’établir les
      finalités de ses traitements de données, de fournir à ses prospects et
      clients, à partir de la collecte de leurs consentements, une information
      complète sur le traitement de leurs données personnelles et de maintenir
      un registre des traitements conforme à la réalité.<br />
      Chaque fois que Co.schooling Academy traite des Données Personnelles,
      Co.schooling Academy prend toutes les mesures raisonnables pour s’assurer
      de l’exactitude et de la pertinence des Données Personnelles au regard des
      finalités pour lesquelles Co.schooling Academy les traite.
      <Subtitle>7.2 Finalité des données collectées</Subtitle>
      Co.schooling Academy est susceptible de traiter tout ou partie des
      données:
      <br />- pour permettre la navigation sur le Site et la gestion et la
      traçabilité des prestations et services commandés par l’utilisateur:
      données de connexion et d’utilisation du Site, facturation, historique des
      commandes, etc.
      <br />- pour prévenir et lutter contre la fraude informatique (spamming,
      hacking…): matériel informatique utilisé pour la navigation, l’adresse IP,
      le mot de passe (hashé)
      <br />- pour améliorer la navigation sur le Site: données de connexion et
      d’utilisation
      <br />- pour mener des enquêtes de satisfaction facultatives sur
      Co.schooling Academy: adresse email
      <br />- pour mener des campagnes de communication (sms, mail): numéro de
      téléphone, adresse email
      <br />
      <br /> Co.schooling Academy ne commercialise pas vos données personnelles
      qui sont donc uniquement utilisées par nécessité ou à des fins
      statistiques et d’analyses.
      <Subtitle>7.3 Droit d’accès, de rectification et d’opposition</Subtitle>
      Conformément à la réglementation européenne en vigueur, les Utilisateurs
      de Co.schooling Academy disposent des droits suivants:
      <br />- droit d'accès (article 15 RGPD) et de rectification (article 16
      RGPD), de mise à jour, de complétude des données des Utilisateurs droit de
      verrouillage ou d’effacement des données des Utilisateurs à caractère
      personnel (article 17 du RGPD), lorsqu’elles sont inexactes, incomplètes,
      équivoques, périmées, ou dont la collecte, l'utilisation, la communication
      ou la conservation est interdite
      <br />- droit de retirer à tout moment un consentement (article 13-2c
      RGPD)
      <br />- droit à la limitation du traitement des données des Utilisateurs
      (article 18 RGPD)
      <br />- droit d’opposition au traitement des données des Utilisateurs
      (article 21 RGPD)
      <br />- droit à la portabilité des données que les Utilisateurs auront
      fournies, lorsque ces données font l’objet de traitements automatisés
      fondés sur leur consentement ou sur un contrat (article 20 RGPD)
      <br />- droit de définir le sort des données des Utilisateurs après leur
      mort et de choisir à qui Co.schooling Academy devra communiquer (ou non)
      ses données à un tiers qu’ils aura préalablement désigné. Dès que
      Co.schooling Academy a connaissance du décès d’un Utilisateur et à défaut
      d’instructions de sa part, Co.schooling Academy s’engage à détruire ses
      données, sauf si leur conservation s’avère nécessaire à des fins
      probatoires ou pour répondre à une obligation légale.<br />
      <br />
      Si l’Utilisateur souhaite savoir comment Co.schooling Academy utilise ses
      Données Personnelles, demander à les rectifier ou s’oppose à leur
      traitement, l’Utilisateur peut contacter Co.schooling Academy par écrit à
      l’adresse suivante: <br />
      Co.schooling Academy — DPO, Marion Guigue <br />
      16 rue Frédéric Bazille 34000 Montpellier <br />
      <br />
      Dans ce cas, l’Utilisateur doit indiquer les Données Personnelles qu’il
      souhaiterait que Co.schooling Academy corrige, mette à jour ou supprime,
      en s’identifiant précisément avec une copie d’une pièce d’identité (carte
      d’identité ou passeport). Les demandes de suppression de Données
      Personnelles seront soumises aux obligations qui sont imposées à
      Co.schooling Academy par la loi, notamment en matière de conservation ou
      d’archivage des documents.<br />
      Enfin, les Utilisateurs de Co.schooling Academy peuvent déposer une
      réclamation auprès des autorités de contrôle, et notamment de la CNIL
      (https://www.cnil.fr/fr/plaintes).
      <Subtitle>7.4 Non-communication des données personnelles</Subtitle>
      Co.schooling Academy s’interdit de traiter, héberger ou transférer les
      Informations collectées sur ses Clients vers un pays situé en dehors de
      l’Union européenne ou reconnu comme « non adéquat » par la Commission
      européenne sans en informer préalablement le client.<br />
      Pour autant, Co.schooling Academy reste libre du choix de ses
      sous-traitants techniques et commerciaux à la condition qu’il présentent
      les garanties suffisantes au regard des exigences du Règlement Général sur
      la Protection des Données (RGPD: n° 2016-679).<br />
      Co.schooling Academy s’engage à prendre toutes les précautions nécessaires
      afin de préserver la sécurité des Informations et notamment qu’elles ne
      soient pas communiquées à des personnes non autorisées.<br />
      Cependant, si un incident impactant l’intégrité ou la confidentialité des
      Informations du Client est portée à la connaissance de Co.schooling
      Academy, celle-ci devra dans les meilleurs délais informer le Client et
      lui communiquer les mesures de corrections prises. Par ailleurs
      Co.schooling Academy ne collecte aucune « données sensibles ».<br />
      Les Données Personnelles de l’Utilisateur peuvent être traitées par des
      filiales de Co.schooling Academy et des sous-traitants (prestataires de
      services), exclusivement afin de réaliser les finalités de la présente
      politique. Dans la limite de leurs attributions respectives et pour les
      finalités rappelées ci-dessus, les principales personnes susceptibles
      d’avoir accès aux données des Utilisateurs de Co.schooling Academy sont
      principalement les agents de notre service client.*/}
    </Div>

    <Div>
      <Subtitle>8. Notification d’incident</Subtitle>
      Quels que soient les efforts fournis, aucune méthode de transmission sur
      Internet et aucune méthode de stockage électronique n'est complètement
      sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si
      nous prenions connaissance d'une brèche de la sécurité, nous avertirions
      les utilisateurs concernés afin qu'ils puissent prendre les mesures
      appropriées.
      <br />
      Nos procédures de notification d’incident tiennent compte de nos
      obligations légales, qu'elles se situent au niveau national ou européen.
      Nous nous engageons à informer pleinement nos clients de toutes les
      questions relevant de la sécurité de leur compte et à leur fournir toutes
      les informations nécessaires pour les aider à respecter leurs propres
      obligations réglementaires en matière de reporting. Aucune information
      personnelle de l'utilisateur du site Co.schooling Academy n'est publiée à
      l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un
      support quelconque à des tiers.
      <br />
      Seule l'hypothèse du rachat de Co.schooling Academy et de ses droits
      permettrait la transmission des dites informations à l'éventuel acquéreur
      qui serait à son tour tenu de la même obligation de conservation et de
      modification des données vis à vis de l'utilisateur du site Co.schooling
      Academy.
    </Div>

    {/*    <Div>
      <Subtitle>9. Sécurité</Subtitle>
      Lors du traitement des Données Personnelles, Co.schooling Academy comprend
      toutes les mesures raisonnables visant à les protéger contre toute perte,
      utilisation détournée, accès non autorisé, divulgation, altération ou
      destruction.
    </Div>*/}

    <Div>
      <Subtitle>9. Liens hypertextes et « cookies »</Subtitle>
      Le site Co.schooling Academy contient un certain nombre de liens
      hypertextes vers d’autres sites, mis en place avec l’autorisation de
      Co.schooling Academy. Cependant, Co.schooling Academy n’a pas la
      possibilité de vérifier le contenu des sites ainsi visités, et n’assumera
      en conséquence aucune responsabilité de ce fait.
      <Subtitle>9.1. « COOKIES »</Subtitle>
      Le site Co.schooling Academy n'utilise pas de cookies.
    </Div>

    <Div>
      <Subtitle>10. Droit applicable et attribution de juridiction.</Subtitle>
      Tout litige en relation avec l’utilisation du site Co.schooling Academy
      est soumis au droit français. En dehors des cas où la loi ne le permet
      pas, il est fait attribution exclusive de juridiction aux tribunaux
      compétents de Montpellier.
    </Div>
  </Wrapper>
)

const Wrapper =
  Component.min_h100vh.bg_light_blue_csa.blue_csa.pt50.pb100.ph100.ph50__s.div()
const Href = Component.blue_csa.b_blue_csa.fs25.bb.inline.a()
